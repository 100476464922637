<tr class="grid-row position-relative">
    <td class="grid-cell">{{ resultData.filial_name || 'N/a' }}</td>
    <td class="grid-cell">
        <template v-if="showEditor">
            <div class="form-group">
                <input v-model="resultData.order_id" :class="[{ 'error': errors.length }, 'form-control']" type="text">
            </div>
        </template>
        <template v-else>
            <span :class="{ 'text-red': errors.length }">
                {{ resultData.order_id || 'N/a' }}
            </span>
        </template>
        <p v-for="error in errors" class="text-red">{{ error }}</p>
    </td>
    <td class="grid-cell">
        <template v-if="showEditor">
            <div class="form-group">
                <input v-model="resultData.partner_code" class="form-control" type="text">
            </div>
        </template>
        <template v-else>
            {{ resultData.partner_code || 'N/a' }}
        </template>
    </td>
    <td class="grid-cell">{{ resultData.create_date }}</td>
    <td class="grid-cell">{{ resultData.edit_date }}</td>
    <td class="grid-cell">
        <div class="d-flex">
            <template v-if="showEditor">
                <button
                    @click="saveAfterEdit"
                    class="btn-sm text-green btn mr-1">
                    <span class="btn-inner">
                        <span class="icon bg-60 check mr-0"></span>
                    </span>
                </button>
                <button
                    @click="showEditor = false"
                    class="btn-sm btn text-red mr-1">
                    <span class="btn-inner">
                        <span class="icon bg-60 close mr-0"></span>
                    </span>
                </button>
            </template>
            <template v-else>
                <Button @click="showEditor = true" label="✎" :attributes="{'class':['btn-sm','btn-green', 'mr-1']}"></Button>
            </template>
            <a
                :href="`questionnaire2/edit?result_code=${resultData.result_code}&isPersonal=1`"
                target="_blank"
                class="btn-sm btn-yellow btn mr-1">
                <span class="btn-inner">
                    <span class="icon bg-60 external-link mr-0"></span>
                </span>
            </a>
            <Button
                v-if="questionnaireIsComplete && resultIsBind"
                label="Отправить"
                type="a"
                :attributes="{
                    'class' : ['btn-sm','btn-blue'],
                    'href' : createKpUrl,
                    'target' : '_blank',
                }"
            ></Button>
            <Button
                v-else-if="resultIsBind"
                label="Опросник"
                type="a"
                :attributes="{
                    'class' : ['btn-sm','text-red'],
                    'href' : `questionnaire2/edit?result_code=${resultData.result_code}&isPersonal=1`,
                    'target' : '_blank',
                }"
            ></Button>
            <Button v-else
                @click="bindQuestionnaireResult"
                label="Привязать"
                :preloader="loaded"
                :attributes="{
                    'class' : ['btn-sm', 'text-blue'],
                    'disabled' : loaded,
                }"></Button>
        </div>
    </td>
</tr>
