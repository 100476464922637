<div class="container">
    <div v-if="managers.length" class="row filter-block my-3">
        <div class="col-3 p-0">
            <Multiselect
                v-model="currentUserId"
                :options="managers"
                :label="'username'"
                placeholder="Менеджер"
                valueProp="id"
                trackBy="username"
                :searchable="true"
                maxHeight="500"
                @select="updateData"
            />
        </div>
    </div>
    <div class="my-3 position-relative">
        <div v-if="loaded" class="preloader preloader--overlay size-2x" ></div>

        <div v-if="!loaded && errors" class="my-3">
            <p v-for="error in errors" class="w100 bg-red text-red my-1 p-1">{{ error }}</p>
        </div>

        <div v-if="!loaded && !offers.length && !errors.length " class="my-3">
            <p class="text-center">Оферты не найдены</p>
        </div>

        <template v-if="offers.length">
            <transition-group name="flip-list">
                <div v-for="(offer, index) in offers" :key="offers.id">
                    <div class="border mb-2">
                        <div class="row py-2">
                            <div class="col">
                                <div>
                                    <strong>
                                        Номер оферты:
                                    </strong>
                                </div>
                                <div>
                                    <a :href="`${hostUrl}/offer/${offer.offer_hash}?manage=1`" target="_blank">{{ offer.offer_id }}</a>
                                </div>
                            </div>
                            <div class="col">
                                <div>
                                    <strong>
                                        Номер контрагента:
                                    </strong>
                                </div>
                                <div>
                                    {{ offer.partner_external_key || 'Номер не указан' }}
                                </div>
                            </div>
                            <div class="col">
                                <div>
                                    <strong>
                                        Ссылка на КП:
                                    </strong>
                                </div>
                                <div>
                                    <template v-if="offer.kp_hash">
                                        <a :href="`${hostUrl}/kp/edit/${offer.kp_hash}`" target="_blank">Открыть редактор</a>
                                    </template>
                                    <template v-else>
                                        КП не создано
                                    </template>
                                </div>
                            </div>
                            <div class="col">
                                <div>
                                    <strong>
                                        Дата создания:
                                    </strong>
                                </div>
                                <div>
                                    {{ offer.create_date }}
                                </div>
                            </div>
                            <div class="col-auto text-right" style="min-width: 320px">
                                <button v-if="Object.values(offer.questionnaire_results).length"
                                    @click="showHideQuestionnaireResults(index)"
                                    class="btn-sm btn"
                                    :class="{ 'btn-blue': Object.values(offer.questionnaire_results).length }" >
                                    <span class="btn-inner">Похожие опросы ({{ Object.values(offer.questionnaire_results).length }})</span>
                                </button>
                                <a v-if="offer.questionnaire_id"
                                    :href="getNewQuestionnaireUrl(offer)"
                                    target="_blank"
                                    class="btn-sm btn-green btn">
                                    <span class="btn-inner">Создать опрос</span>
                                </a>
                                <span v-else>Опросник для подбора товара не определен</span>
                            </div>
                        </div>
                        <div v-show="showQuestionnaireResultsArray.includes(index)" class="row">
                            <div v-if="Object.values(offer.questionnaire_results).length" class="col p-0">
                                <hr>
                                <table style="grid-template-columns: minmax(100px, 2fr) minmax(100px, 2fr) minmax(100px, 2fr) minmax(100px, 2fr) minmax(100px, 2fr) minmax(260px, 2fr);">
                                    <thead>
                                    <tr>
                                        <th class="grid-cell">Филиал</th>
                                        <th class="grid-cell">№ заказа</th>
                                        <th class="grid-cell">№ контрагента из 1с</th>
                                        <th class="grid-cell">Дата создания</th>
                                        <th class="grid-cell">Дата обновления</th>
                                        <th class="grid-cell">Действия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="result in offer.questionnaire_results">
                                        <QuestionnaireResultRow :result="result" :offer="offer" />
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </template>
    </div>

<!--    <div v-if="allPages > 1 && offers.length" class="pagination__wrapper">-->
<!--            <ul class="pagination">-->
<!--                <li>-->
<!--                    <button class="prev" @click="prevPage" :disabled="pageNumber===0">&#10094;</button>-->
<!--                </li>-->
<!--                <template v-for="(number,key) in allPages">-->
<!--                    <li @click="key !== pageNumber ? openPage(key) : ''"-->
<!--                        v-if="key === 0 || number === allPages || (pageNumber + 7 >= key && pageNumber - 7 <= key)">-->
<!--                        <button-->
<!--                            v-bind:class="{'active':pageNumber === key,'side_numbers':key === 0 || number === allPages}">-->
<!--                            {{ number }}-->
<!--                        </button>-->
<!--                    </li>-->
<!--                </template>-->
<!--                <li>-->
<!--                    <button class="next" @click="nextPage" :disabled="pageNumber === allPages - 1">&#10095;</button>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->
</div>
