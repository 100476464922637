<template src="./templates/user-offers.html"></template>

<script>
    import Button from '@f/Button';
    import '../../layouts/default/styles/partners.scss';

    import Multiselect from '@vueform/multiselect';
    import '@vueform/multiselect/themes/default.css';

    import {
        getManagersList,
        getUserOffers,
        getCountUserOffers
    } from '@api/user';
    import QuestionnaireResultRow from './components/questionnaireResultRow';


    export default {
        name: 'UserOffers',
        components: {
            Button,
            QuestionnaireResultRow,
            Multiselect,
        },
        props: {
            size: {
                type: Number,
                required: false,
                default: 15
            }
        },
        data() {
            return {
                loaded: true,
                pageNumber: 0,
                allPages: 0,
                countOffers: 0,
                offers: [],
                errors: [],
                showQuestionnaireResultsArray: [],
                currentUserId: null,
                managers: []
            };
        },
        computed: {
            hostUrl() {
                return process.env.VUE_APP_HOST_URL;
            },
        },
        async created() {
            await this.getManagers();
            this.currentUserId = this.$store.state.User.info.user_id;
            await this.compileData(this.pageNumber * this.size);
            this.loaded = false;
        },
        methods: {
            async nextPage() {
                this.pageNumber++;
                await this.compileData(this.pageNumber * this.size);
            },
            async prevPage() {
                this.pageNumber--;
                await this.compileData(this.pageNumber * this.size);
            },
            async openPage(key) {
                this.pageNumber = key;
                await this.compileData(this.pageNumber * this.size);
            },
            async compileData(offset) {
                this.loaded = true;
                this.offers = await getUserOffers(this.currentUserId, offset).catch((e) => this.errors.push(e));
                this.countOffers = await getCountUserOffers(this.currentUserId).catch((e) => this.errors.push(e));
                this.loaded = false;
                this.allPages = Math.ceil(this.countOffers / this.size);
            },
            async updateData() {
                this.pageNumber = 0;
                await this.compileData(this.pageNumber * this.size);
            },
            async getManagers() {
                this.managers = await getManagersList();
            },
            showHideQuestionnaireResults(index) {
                if (this.showQuestionnaireResultsArray.indexOf(index) !== -1) {
                    this.showQuestionnaireResultsArray.splice(this.showQuestionnaireResultsArray.indexOf(index), 1);
                } else {
                    this.showQuestionnaireResultsArray.push(index);
                }
            },
            getNewQuestionnaireUrl(offer) {
                // TODO 12 передавать продукты массивом
                return `/questionnaire2/new/${offer.questionnaire_id}?`
                    // в ссылку на новый опрос филиал подставляем из юзера
                    + `b_id=${this.$store.state.User.info.filial.external_id}`
                    + `&p_id=${offer.partner_external_key}`
                    + `&p_n=${offer.partner_name}`
                    + `&person=${offer.partner_contact}`
                    + `&or_n=${offer.offer_id}`
                    + `&prod_external_id=${offer.selected_products.external_id}`
                    + `&payment_type=${offer.selected_products.payment_type}`
                    + `&price=${offer.selected_products.price}`
                    + '&isPersonal=1';
            }
        }
    };
</script>
