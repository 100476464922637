<template src="../templates/questionnaireResultRow.html"></template>

<script>
    import Button from '@f/Button';
    import {updateQuestionnaireResult} from '@api/user';

    export default {
        name: 'QuestionnaireResultRow',
        components: {
            Button,
        },
        props: {
            result: {
                type: Object,
                required: true,
                default() {
                    return {};
                },
            },
            offer: {
                type: Object,
                required: true,
                default() {
                    return {};
                },
            },
        },
        data() {
            return {
                loaded: false,
                showEditor: false,
                order_id: null,
                resultData: null,
                errors: [],
            };
        },
        computed: {
            createKpUrl() {
                let link = `${process.env.VUE_APP_HOST_URL}/kp/create?external_id=${this.offer.selected_products.external_id}`;
                link += `&questionnaire_results_id=${this.result.result_code}`;
                link += `&filial_id=${this.result.filial_id}`;

                return link;
            },
            questionnaireIsComplete() {
                return this.result.questionnaire_is_complete;
            },
            resultIsBind() {
                return this.order_id;
            },
        },
        async created() {
            this.resultData = {...this.result};
            this.resultData.selected_products = this.offer.selected_products;
            this.order_id = this.resultData.order_id;
        },
        methods: {
            saveAfterEdit() {
                this.updateQuestionnaireResult(this.resultData);
            },
            bindQuestionnaireResult() {
                this.resultData.order_id = this.offer.offer_id;
                this.resultData.partner_code = this.offer.partner_external_key;

                this.updateQuestionnaireResult(this.resultData);
            },
            async updateQuestionnaireResult(result) {
                this.errors = [];
                this.loaded = true;

                try {
                    this.order_id = await updateQuestionnaireResult(result);
                    this.showEditor = false;
                } catch (error) {
                    this.order_id = null;
                    this.errors.push(error);
                }

                this.loaded = false;

            }
        }
    };
</script>
